import {mapActions, mapGetters, mapMutations} from "vuex";
import AOS from 'aos';
import 'aos/dist/aos.css';

//sections

export default {
  name: "about",
  components:{
  },
  data(){
    return{

    }
  },
  created() {
    this.fetchAbout()
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  computed:{
    ...mapGetters({
      about: 'home/about'
    })
  },

  methods:{
    ...mapActions({
      fetchAbout: 'home/GET_ABOUT'
    }),
    ...mapMutations({
    })
  }
}